import { useContext, useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import konsole from './Network/konsole';
import Services from './Network/Services';
import { AoLoginBaseUrl } from './Network/url';
import '../components/CustomeIdleTimer.css'
import commonLib from './control/commonLib';
import { globalData } from './Execute/CommonModal.js';

export default function CustomeIdleTimer() {

    const timeout = 15 * 60 * 1000;
    const promptBeforeIdle = 1 * 60 * 1000;
    const [state, setState] = useState('Active')
    const [remaining, setRemaining] = useState(timeout / 1000)
    const [open, setOpen] = useState(false)
    const { setloader } = useContext(globalData);
    const [activeButton, setActiveButton] = useState('continue')
    const [logoutClicked, setLogoutClicked] = useState(false);

    const logoutCalled = useRef(false);

    const onIdle = () => {
        setState('Idle')
        setOpen(false)
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
        setActiveButton('continue')
    }

    const { getRemainingTime, activate, pause } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    const postLogout = async () => {
        return new Promise(async (resolve) => {
            if (logoutCalled.current) {
                resolve(false);
                return;
            }
            logoutCalled.current = true;
            setloader(true);
            const promises = await Services.postLogUserout();
            konsole.log(promises,"promises")
            setloader(false);
            resolve(true)
        });
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            const remainingTime = Math.ceil(getRemainingTime() / 1000);
            setRemaining(remainingTime);

            if (remainingTime <= 0 && !logoutClicked) {
                setOpen(false)
                const result = await postLogout();
                if (result == true) {
                    sessionStorage?.clear()
                    window.location.href = `${AoLoginBaseUrl}Account/Signin?expired=true`;
                }
            }
        }, 1000);

        // Below Code Is To Disable Timer For Localhost
        if(window.location.href?.includes('localhost:') == true) {
            clearInterval(interval);
            pause()
        }

        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime]);

    const getSubtenantDetails = () => {
        setloader(true);
        const subtenantId = commonLib.getObjFromStorage("subtenantId");
        const promises = Services.getSubtenantDetail(subtenantId, true);
        promises.then(res => {
            setloader(false);
            konsole.log("subtenantDetails", res);
        }).catch(err => {
            konsole.log("error", err.response);
        }).finally(() => {
            konsole.log("fin");
            setloader(false);
        })
    }

    const handleStillHere = () => {
        getSubtenantDetails();
        activate()
        setOpen(false);
        setActiveButton('')
    }

    const handleLogoutNow = () => {
        setOpen(false)
        setLogoutClicked(true);
        window.location.href = `${AoLoginBaseUrl}Account/Signin`;
        sessionStorage?.clear()
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const secs = seconds % 60
        return `${String(minutes)?.padStart(2, '0')} : ${String(secs)?.padStart(2, '0')}`
    }
    
    return (
        <>
            <Modal id="idleTimerModal" show={open} onHide={handleStillHere} className={`customModal ${open ? 'slide-down' : ''}`}>
                <Modal.Header closeButton className="d-flex justify-content-between"></Modal.Header>
                <Modal.Body className='text-center'>
                    <Col className='d-flex justify-content-center'>
                        <Row className='idleTimerClock'>
                            <img src='/icons/idleTimerClock.svg' />
                        </Row>
                    </Col>
                    <Col className='idleTimerContent'>
                        <Row>
                            <h1>Are you still here?</h1>
                        </Row>
                        <Row>
                            <p className='pt-0'>In order to keep your account safe, we periodically check to see if you’re still here. You can stay logged in by clicking below.</p>
                        </Row>
                    </Col>
                    <Col className='idleTimerSession'>
                        <Row>
                            <h3 className='pb-0'>Your current session will expire in:</h3>
                        </Row>
                        <Row>
                            <h2>{formatTime(remaining)}</h2>
                        </Row>
                    </Col>
                    <Col className='idleTimerButton'>
                        <Row>
                            <Col>
                                <button
                                    onMouseEnter={() => setActiveButton('logout')}
                                    onMouseLeave={() => setActiveButton('continue')}
                                    onClick={handleLogoutNow}
                                    className={activeButton === 'logout' ? 'active' : ''}
                                >
                                    Log out now
                                </button>
                            </Col>
                            <Col>
                                <button
                                    onMouseEnter={() => setActiveButton('continue')}
                                    onMouseLeave={() => setActiveButton('continue')}
                                    onClick={handleStillHere}
                                    className={activeButton === 'continue' ? 'active' : ''}
                                >
                                    Continue session
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}
