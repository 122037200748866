import moment from 'moment';
import { AoLoginBaseUrl } from '../Network/url';
const commonLib = {
    closeParentModal:function(){
        window.parent.postMessage('closeModal', '*');
    },
    getQueryParameters: function (urlQuery, name) {
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    calculate_age: (dob1) => {
        let today =  new Date();
        if(dob1 == null){
            return 0;
        }
        let birthOfDate = dob1.split("T")[0];
        let birthDate = new Date(birthOfDate);
        let age_now = today.getFullYear() - birthDate.getFullYear();

        return age_now;
      },
      getFormattedDate: function (value) {
        return moment(value).format('MM/DD/YYYY')
      },
      getFormattedTime: function(datetime){
        return moment(datetime, "HH:mm").format("hh:mm A");
    },

    DEFAULT_SUBTENANTID_FOR_OCCURRENCE:2,


    
      GetQueryValues: (query, param) => {
        var url = "";
        url = query.slice(query.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
            var urlparam = url[i].split('=');
            if (urlparam[0] == param) {
                return urlparam[1].split("#")[0];
            }
        }
        return "";
    },
    getQueryValuefromUrl:(queryString,value)=>{
        const urlParams = new URLSearchParams(queryString);
        const data = urlParams.get(value);
        return window.atob(data);

    },
    unAuthurizeAccess : () => {
        window.location.replace(`${AoLoginBaseUrl}Account/Signin?expired=true`)
      },
    getUniqueArrayFromAgentUsr: (userAgentArray) => {
        const uniqueArr = [...new Set(userAgentArray.map(item => item.memberUserId))].map(name => { return userAgentArray.find(item => item.memberUserId === name);});
        
        return uniqueArr;
    },
    showFullName: ({fName, mName, lName}) => {
        let name = fName;
        if(mName !== null && mName !== "") name += ` ${mName}`;
        name += ` ${lName}`;
        return name;
    },
    convertToUSFormat:(mobileNumber, countryCode)=> {
        const digitsOnly = mobileNumber.replace(/\D/g, '');
        const formattedNumber = digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, `+${countryCode} ($1) $2-$3`);
        return formattedNumber;
      },
      convertToIndiaFormat: (mobileNumber, countryCode) => {
        const digitsOnly = mobileNumber.replace(/\D/g, '');
        const formattedNumber = digitsOnly.replace(/(\d{5})(\d{5})/, `+${countryCode} $1-$2`);
        return formattedNumber;
    }  

}


export default commonLib;