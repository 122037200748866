import React, { createContext, useEffect, useMemo, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Main from '../Main'
import './Modal.css'
import Services from '../Network/Services';
import konsole from '../Network/konsole';
import commonLib from '../control/commonLib';
import OccuranceCom from '../OccuranceCom';
import { isNotValidNullUndefinedfile } from '../control/Constant';
// import { DraggableModalDialog } from './DraggableModalDialog';

import FinancelRole from './FinancialRole';
import Stept2SelectOne from './Step2SelectOne';
import Step3SelectOption from './Step3SelectOption';
import IllnessWarning from './Illness/IllnessWarning';
import IllnessNotifySuccessor from './Illness/IllnessNotifySuccessor';
import Step4illness from './Illness/Step4illness';
import IllnessHospitalStep6 from './Illness/IllnessHospitalStep6';
import IllnessHospitalStep7 from './Illness/IllnessHospitalStep7';
import IllnessHomeMedical from './Illness/IllnessHomeMedical';
import IllnessHomeMedicalHelp from './Illness/IllnessHomeMedicalHelp';
import IllnessHomeGoalStep7 from './Illness/IllnessHomeGoalStep7';
import IllnessEmailTextPreview from './Illness/IllnessEmailTextPreview';
import Feedback from '../Feedback/Feedback';


import EOLYesNo from './EndOfLife/EOLYesNo';
import EOLwarning from './EndOfLife/EOLwarning';
import EOLNotifySuccessorStep4 from './EndOfLife/EOLNotifySuccessorStep4';
import EOLYesNeedNo from './EndOfLife/EOLYesNeedNo';
import EOLNeedYes from './EndOfLife/EOLNeedYes'
import EOLNeedYesUnanimous from './EndOfLife/EOLNeedYesUnanimous';
import EOLGoalStep7 from './EndOfLife/EOLGoalStep7';
import EOLGcmStep8 from './EndOfLife/EOLGcmStep8';
import EOLAccessDocument from './EndOfLife/EOLAccessDocument';
import EOLNeedHelpStep7 from './EndOfLife/EOLNeedHelpStep7'
import EOLNeedHelpStep9 from './EndOfLife/EOlNeedHelpStep9';
import EOLGcmContact from './EndOfLife/EOLGcmContact'
import EOLStep10 from './EndOfLife/EOLStep10';
import EOLstep9Call from './EndOfLife/EOLstep9Call';
import EOLEmailTextPreview from './EndOfLife/EOLEmailTextPreview';
import EOLEmailforElderAttorney from './EndOfLife/EOLEmailforElderAttorney';
import EOlAttornerCall from './EndOfLife/EOLAttorneyCall';
import ContactToelderlawattorney from './EndOfLife/ContactToelderlawattorney';


import DeathWarning from './Death/DeathWarning';
import DeathNotifySuccessorStep4 from './Death/DeathNotifySuccessorStep4';
import DeathStep5 from './Death/DeathStep5';
import DeathHome from './Death/DeathHome';
import DeathGoalStep7 from './Death/DeathGoalStep7';
import DeathAskAgent from './Death/DeathAskAgent';
import DeathCrematedBuried from './Death/DeathCrematedBuried';
import DeathCrematedYesNo from './Death/DeathCrematedYesNo';
import DeathCrematedViewGuidance from './Death/DeathCrematedViewGuidance';
import DeathBuriedNo from './Death/DeathBuriedNo';
import DeathEmailTextPreview from './Death/DeathEmailTextPreview';
import FinessWarming from '../finance/finanaceWarning';
import FinessNotifySuccessor from '../finance/financeSuccessorwarning';
import FinanceOptions from '../finance/financeOptions';
import FinanceGoalNStep from '../finance/financeGoalNStep';
import ManageReal from '../finance/ManageReal';
import FinanceGoalNStep2 from '../finance/financeGoalNStep2';
import Filetaxes from '../finance/filetaxe';
import PaybiilsByYourself from '../finance/paybiilsByYourself';
import FileTaxesByYourself from '../finance/fileTaxesByYourself';
import ManageInverstment from '../finance/manageInvestigation';
import FinanceContactPage from '../finance/FinaceContactPage';
import FinanceEmailNText from '../finance/financeEmailNText';
import FinanceCall from '../finance/financeCall';
import MentalHealthYesOrNo from '../MentalHealth/MentalHealthYesorNo';
import MentalHealthSafe from '../MentalHealth/MentalHealthSafe';
import MentalHealthGCM from '../MentalHealth/MentalHealthFindGCM';
import MentailHealthCallNine from '../MentalHealth/MentailHealthCallNine';
import MentalHealthDiscuss from '../MentalHealth/MentalHealthDiscuss';
import AccessDocuments from '../AccessDocument/AccessDocuments';
import MentalHealthSuccessorContact from '../MentalHealth/MentalHealthSuccessorContact';
import { useLocation } from 'react-router-dom';
import HospitalDetails from '../HospitalDetails';
import OtherSetting from '../OtherSetting';
import IllnessWrngMember from './Illness/IllnessWrngMember';
import ModalInsurence from './ModalInsurence';
import ElaNotify from './Illness/ElaNotify';
import { loginUserEmail ,removeDuplicateValue} from '../control/Constant';

// import Draggable from 'react-draggable';
// import ModalDialog from 'react-bootstrap/ModalDialog';


export const globalData = createContext()

const CommonModal = () => {
  const [stepsToFollow, manageStepToFollow] = useState({});
  const [agentuserId, setAgentuserId] = useState()
  const [selectoptionId, setSelectoptionId] = useState(0)
  const [selectoptionmessage, setSelectoptionmessage] = useState('')
  const [notifyTypeId, setNotifyTypeId] = useState(null);
  const [modalcontantshow, setmodalcontantshow] = useState("step2selectone")
  const [sendEmailOrText, setSendEmailOrText] = useState("")
  const [show, setShow] = useState(false)
  const [steptwomodaldata, setsteptwomodaldata] = useState({})
  const [illushospitalstep6help, setillushospitalstep6help] = useState()
  const [Eolneedhelpsetp7to8, setEolneedhelpsetp7to8] = useState()
  const [EOlStep8Toallcall, setEOlStep8Toallcall] = useState()
  const [Eolstep10, setEOLstep10] = useState()
  const [deathcrematedburied, setcrematedburied] = useState()
  const [deathhome, setdeathhome] = useState()
  const [step7illnesshomenext, setstep7illnesshomenext] = useState()
  const [flowWithoutProff, setFlowWithoutProff] = useState('')
  const [withOutGCMNeedHelpNext, setwithOutGCMNeedHelpNext] = useState()
  const [fullnameofAlderlawattorny, setFullnameofalderlawattorney] = useState()
  const [hospitalDetails, setHospitalDetails] = useState()
  const [settingDetails, setSettingDetails] = useState()
  const [goBack, setGoBack] = useState("")
  const [goNext, setGoNext] = useState("")
  const [insurenceJosn, setInsurenceJosn] = useState()
  const [lawFirmMail, setLawFirmMail] = useState("")
  const [legalDoc, setlegalDoc] = useState(null)
  const [allProfessionals, setallProfessionals] = useState()
  // GCM
  const [isGCM, setIsGCM] = useState("")
  const [successalert, setsuccessalert] = useState(false)
  const [showDocument, hideDocument] = useState(false);
  const [gcmDetails, setGcmDetails] = useState([])
  const [agentData, setAgentdata] = useState(null)
  const [fullNameofGcm, setFullNameOfGCM] = useState()
  const [agentRoleid, setAgentroleid] = useState(null)
  const [selectedAgentRolesList, setSelectedAgentRolesList] = useState([]);
  const location = useLocation();
  const [wayToconnect, setWaytoconnect] = useState("")
  const [primaryAgents, setPrimaryagents] = useState(null)
  let occurranceRef = useRef(null)
  const [warninngMsg, setwarninngMsg] = useState(false)
  const [warningText, setWarningText] = useState("")
  const [deathText, setDeathText] = useState("Loading..");
  const [loader, setloader] = useState(false)
  const [newAlert, setnewAlert] = useState({type: "", text: ""});
  const [certifyAgent, setCertifyAgent] = useState("")
  const [allMembers, setallMembers] = useState("")
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [rolesName, setRolesName] = useState("")
  const [selectedFinacePage, setSelectedFinancePage] = useState("");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const [gcmidforcontactno, setgcmidforcontactno] = useState();
  const [GCmcontactNo, setGecContactNo] = useState("No Cell Number")
  const [memberRankIdforPrimary, setmemberRankIdforPrimary] = useState('')
  const [agentRole, setAgentrole] = useState(null)
  const [allAgents, setAllagents] = useState(null)
  const [alljsonData, setAlljsondata] = useState(null)
  const [stepName, setStepName] = useState("")
  const [elderlawAttorney, setelderlawAttorney] = useState('')
  const [lenderemailtexttype, setlenderemailtexttype] = useState('Email');



  const lengthdsfsdf = useMemo(() => {
    const lenghtof = Object.keys(steptwomodaldata).length;
    return lenghtof;
  }, [steptwomodaldata])
  useEffect(() => {
    getKeysFormAORG();
  }, [])

  const getKeysFormAORG = async () => {
    const openPage = commonLib.getSessionStoarge("openPage")
    const selectedRole = commonLib.getSessionStoarge("selectedRole")
    const selectedModule = commonLib.getSessionStoarge("selectedModule")
    const memberUserId = commonLib.getSessionStoarge("memberUserId")
  if (openPage !== "" && selectedRole !== "" && selectedModule !== "" && memberUserId !== "") {
      const getKeyPoints = JSON.parse(localStorage.getItem(("keypoints")))
     if (getKeyPoints !== null) await intializeTheState(getKeyPoints)
      if (openPage == "manageInverstment" || openPage == "managerealproperty") {
        setTimeout(() => {
          setShow(true);
        }, 1000)
      }
      else {
        let selectedRoles = selectedRole != null && selectedRole != undefined && selectedRole != "";
        let memberUserid = memberUserId != null && memberUserId != undefined && memberUserId != "";
        if (selectedRoles == true && memberUserid == true) {
        await getGCMprofessionalbyUserid(selectedRole, true, memberUserId)
        }

      }

      if (openPage == "eolneedhelpstep9"){
       await getGCMprofessionalbyUseridforLocate(13, true, memberUserId)
      }
      getUsersPrimaryAgent(memberUserId,selectedModule)
      setmodalcontantshow(openPage);
    }
  }

  const intializeTheState = async (getKeyPoints) => {
    return new Promise((resolve, reject) => {
      for (const [key, value] of Object.entries(getKeyPoints)) {
        if (key == "pageName") continue;
        if (key === 'setsteptwomodaldata') setsteptwomodaldata(value);

        const vass = eval(`"${value}"`)
        eval(`${key}("${vass}")`);
      }
      resolve(true);
    })
  }


  const handleClose = () => {
    setShow(false);
    setmodalcontantshow('step2selectone')
    setstep7illnesshomenext()
    setdeathhome()
    setEOLstep10()
    setIsGCM('false')
    setillushospitalstep6help('');
    setwithOutGCMNeedHelpNext();
    setstep7illnesshomenext();
    setGcmDetails();
    setFullNameOfGCM();
    setgcmidforcontactno();
    setGecContactNo();
    setEOlStep8Toallcall();
    setEolneedhelpsetp7to8();
    setillushospitalstep6help();
    setsteptwomodaldata({});
    setSelectoptionmessage();
    // setAgentuserId();
    setSelectoptionId();
    setIsGCM();
    setSelectedFinancePage("");
    setmemberRankIdforPrimary('setmemberRankIdforPrimary')
    setelderlawAttorney('');
    setlenderemailtexttype('');
    manageStepToFollow({});
    localStorage.removeItem("keypoints");
    sessionStorage.removeItem("openPage");
    sessionStorage.removeItem("selectedRole")
    sessionStorage.removeItem("selectedModule")
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    const agentuserId = commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId;
    setAgentuserId(agentuserId)
  }, [])



  const handleShowDocumentModal = () => {
    hideDocument(true);
  };




 
  const NotifyContinueCallApi = async() => {
    if (stateObj.roleId == "5") {
      let subtenantId = sessionStorage.getItem('subtenantId')
      let userLoggedInDetail = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
   
      if(illushospitalstep6help == "Illness"){
        for (let i = 0; i < emails?.length; i++) {
          let professUserData = {
            notifyText: selectoptionmessage,
            agentName: userLoggedInDetail?.loginUserName,
            agentEmail: loginUserEmail(),
            agentMobileNo: userLoggedInDetail?.primaryPhoneNumber,
            memberFullName: emails[i]?.relativeName,
            userEmail: emails[i]?.relativeEmailId,
            usersUserId: emails[i]?.memberUserId,
            userPhoneNo: emails[i]?.relativeContactNo,
            type: "healthUser",
          }
         {emails.length > 0 &&
          occurranceRef?.current?.calloccuranceIdfun(31, subtenantId, professUserData);}
        }
      }else{
      setloader(true)
      let promise = Services.postsendnotifyemailtomemberrelativeList(1, steptwomodaldata.memberUserId, selectoptionId, selectoptionmessage, agentuserId)
      promise.then((response) => {
        if (response) {
          let responseData = response.data?.data?.memberDetails
     
          let filteredData = responseData?.filter((ele) => {
            return ele?.relativeUserId != userLoggedInDetail?.loggedUserId

          })

           if (!isNotValidNullUndefinedfile(filteredData)) {
            setloader(false)
           } else {
               for (let i = 0; i < filteredData?.length; i++) {
                let professUserData = {
                  notifyText: selectoptionmessage,
                  agentName: userLoggedInDetail?.loginUserName,
                  agentEmail: loginUserEmail(),
                  agentMobileNo: userLoggedInDetail?.primaryPhoneNumber,
                  memberFullName: filteredData[i]?.relativeName,
                  userEmail: filteredData[i]?.relativeEmailId,
                  usersUserId: filteredData[i]?.memberUserId,
                  userPhoneNo: filteredData[i]?.relativeContactNo,
                  type: "healthUser",
                }
                occurranceRef?.current?.calloccuranceIdfun(31, subtenantId, professUserData);
              }
            }
          
        }

      })
        .catch((err) => {
          konsole.log("error", err)
          setloader(false)
        })
      }
        setloader(true)
       await getGCMprofessionalbyUserid(7, true);
       await  getGCMprofessionalbyUseridforLocate(13, true);
        if (selectoptionId == 1) {
        setillushospitalstep6help(goNext)
        if(goNext == "Home" || goNext == "Other"){
          setmodalcontantshow("illnesshomegoalstep7")
        }else if(goNext == "Hospital"){
          setmodalcontantshow('illnesshospitalstep6')
        }
        
      } else if (selectoptionId == 2) {
        setmodalcontantshow('eolyesneedno')
        // setmodalcontantshow('eolemailtextpreview')
      } else if (selectoptionId == 3) {
        // setmodalcontantshow('deathemailtextpreview')
        setmodalcontantshow('deathstep5')
      } else if (selectoptionId == 4) {
      setmodalcontantshow("mentalHealthYesOrNo")
      }else if(selectoptionId == 5){
        // setmodalcontantshow('illnessMemberWarning')
        setSelectoptionId(1)
      }
     

    }
    else {
      setloader(true)
      await getGCMprofessionalbyUserid(7, true);
      await getGCMprofessionalbyUseridforLocate(13, true);
      if (selectoptionId == 1) {
        setillushospitalstep6help(goNext)
        if(goNext == "Home" || goNext == "Other"){
          setmodalcontantshow("illnesshomegoalstep7")
        }else if(goNext == "Hospital"){
          setmodalcontantshow('illnesshospitalstep6')
        }
      } else if (selectoptionId == 2) {
        setmodalcontantshow('eolyesneedno')
      } else if (selectoptionId == 3) {
        setmodalcontantshow('deathstep5')
      }else if (selectoptionId == 4) {
        setmodalcontantshow('step3selectoption')
      }else if(selectoptionId == 5){
        setmodalcontantshow('illnessMemberWarning')
        setSelectoptionId(1)
      }
   
    }
  }


  const getGCMprofessionalbyUseridforLocate = (professId, LPOStatus,memberUserId) => {
    const memberUserIdLocal = memberUserId || steptwomodaldata.memberUserId;
    
    return new Promise((resolve, reject) => {
      Services.getProfessionalService(memberUserIdLocal).then((res)=>{
        setloader(false)
        let selectedELA = res.data.data?.filter((items) => {
          return items.proTypeId == professId && items.lpoStatus == true;
        });
        resolve(res)
        setelderlawAttorney(selectedELA)

      }).catch((err)=>{
        resolve('err')
        setloader(false)
      })
    })
  
  }

  const getGCMprofessionalbyUserid = (professId, LPOStatus, memberUserId) => {
    const memberUserIdLocal = memberUserId || steptwomodaldata.memberUserId;
    // setloader(true)
    return new Promise((resolve, reject) => {
      Services.getProfessionalService(memberUserIdLocal).then((res)=>{
        setloader(false)
        let selectedGCM = res.data.data?.filter((items) => {
          return items.proTypeId == professId && items.lpoStatus == true;
        });
        if(selectedGCM.length > 0){
          setIsGCM('true')
        }else{
          setIsGCM('false')
        }
        setGcmDetails(selectedGCM[0]);
        setgcmidforcontactno(selectedGCM[0].professionalUserId);
  
        let gcmDetails = selectedGCM[0];
  
        let fullName = commonLib.showFullName(gcmDetails)
        setFullNameOfGCM(fullName)
        setShow(true)
        resolve(res)

      }).catch((err)=>{
        setloader(false)
        setIsGCM('false')
        resolve('err')
      })
    })
    
  };


  const getGCMContactCallNo = () => {
    setloader(true)
    let promise = Services.getContactbyuserId(gcmidforcontactno)
    promise.then((res) => {
      setloader(false)
      setGecContactNo(res.data.data.contact.mobiles[0].mobileNo)

    })
      .catch((err) => {
      })
  }


  const postsendnotifytoGCMApi = () => {
    if (stateObj.roleId == "5") {
      let subtenantId = sessionStorage.getItem('subtenantId')
      let userLoggedInDetail = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
      if(flowWithoutProff == "IllnessHospitalGCM"){
        for(let i = 0; i < allProfessionals.length; i++){
        setloader(true)
        let professUserData = {
          notifyText: selectoptionmessage,
          agentName: userLoggedInDetail?.loginUserName,
          agentEmail: loginUserEmail(),
          agentMobileNo: userLoggedInDetail?.primaryPhoneNumber,
          memberFullName: allProfessionals[i]?.proFullName,
          userEmail: allProfessionals[i]?.proPrimaryEmail,
          usersUserId: allProfessionals[i]?.userId,
          userPhoneNo: allProfessionals[i]?.proPrimaryContact,
          type: "healthUser",
        }
        occurranceRef?.current?.calloccuranceIdfunOnlyMailOrText(31, subtenantId, professUserData, wayToconnect)
        setmodalcontantshow('eolneedhelpstep9')
        setAgentuserId();
       }


      }else{
        setloader(true)
        let professUserData = {
          notifyText: selectoptionmessage,
          agentName: userLoggedInDetail?.loginUserName,
          agentEmail: loginUserEmail(),
          agentMobileNo: userLoggedInDetail?.primaryPhoneNumber,
          memberFullName: gcmDetails?.fName + " " + gcmDetails?.lName,
          userEmail: gcmDetails?.emaidIds ?? gcmDetails?.proEmailId,
          usersUserId: gcmDetails?.professionalUserId,
          userPhoneNo: gcmDetails?.mobileNumbers  ?? gcmDetails?.proPhoneNo,
          type: "healthUser",
        }
        occurranceRef?.current?.calloccuranceIdfunOnlyMailOrText(31, subtenantId, professUserData, wayToconnect)
        setmodalcontantshow('eolneedhelpstep9')
        setAgentuserId();
      }
     
    
    }
    else {
      setmodalcontantshow('eolneedhelpstep9')
    }
  }

  const getsuccessmsg = () => {
    setsuccessalert(true)
    setTimeout(() => {
      setsuccessalert(false)
    }, 3000)
  }


  const financeroles = () => {
    // onClick={()=>setmodalcontantshow('financeWarning')}
    if (memberRankIdforPrimary == 1) {
      setmodalcontantshow('financenotifysuccessor')
    } else {
      setmodalcontantshow('financeWarning')
    }
  }

  const addManageStepToFollow = (key, value) => {
    return manageStepToFollow((prev) => { return { ...prev, [key]: value } })
  }
  const getUsersPrimaryAgent = (memberUserId, selectedModule) => {
    setloader(true)
    const stateJson = commonLib.getObjFromStorage('stateObj')?.userId;
    const userId = memberUserId;
    const agentRoleId = selectedModule == "health" ? 1 : 2
    Services.getUseragentsapi("true", userId).then((res) => {
      setloader(false)
      let response = res?.data?.data
      setAllagents(response)
      let filteredJson = response.length > 0 && response.filter((ele) => { return ele?.agentRoleId == agentRoleId && ele?.agentUserId != stateJson.userId })

      setAgentdata(removeDuplicateValue(filteredJson))
      setallMembers(removeDuplicateValue(filteredJson))
      let filteredData = response.length > 0 && response.filter((ele) => { return ele?.agentRoleId == agentRoleId && ele?.agentRankId == 1 })

      if (filteredData.length === 0) {
        let filteredDatas = response.length > 0 && response.filter((ele) => { return (ele?.agentRoleId == agentRoleId && (ele?.agentRankId == 2 || ele?.agentRankId == 3 || ele?.agentRankId == 4 || ele?.agentRankId == 5 || ele?.agentRankId == 6)) })
        setPrimaryagents(removeDuplicateValue(filteredDatas))



      } else {
        setPrimaryagents(removeDuplicateValue(filteredData))
      }



    }).catch((err) => { })



  }
 
  



  return (
    <>

      {
        successalert ?
          <div className='position-fixed top-0 end-0 p-3' style={{ zIndex: '999999' }}>
            <div className="alert alert-success alert-dismissible fade show" >
              <strong>Notification sent successfully</strong>
              <button type="button" className="btn-close" onClick={() => setsuccessalert(!successalert)}></button>
            </div>
          </div> : ""
      }
      {
        warninngMsg ?
          <div className='position-fixed top-0 end-0 p-3' style={{ zIndex: '999999' }}>
            <div className="alert alert-warning alert-dismissible fade show" >
              <strong>{warningText}</strong>
              <button type="button" className="btn-close" onClick={() => setwarninngMsg(!warninngMsg)}></button>
            </div>
          </div> : ""
      }
      {
        (newAlert.text && (newAlert.type == 'success' || newAlert.type == 'warning' || newAlert.type == 'danger')) ? 
          <div className='position-fixed top-0 end-0 p-3' style={{ zIndex: '999999' }}>
            <div className={`alert alert-${newAlert.type} alert-dismissible fade show`} >
              <strong>{newAlert.text}</strong>
              <button type="button" className="btn-close" onClick={() => setnewAlert({type: "", text: ""})}></button>
            </div>
          </div> : ""
      }
       <div className="overlay position-relative">
        {loader &&
          <div className="d-flex align-items-center justify-content-center position-absolute overLayLoader"style={{height:"100vh",width:"100vw",backgroundColor:"#80808082"}}>
            <div className="spinner-border" role="status" aria-hidden="true"></div>
          </div>
        }
        </div>


      <globalData.Provider value={{ modalcontantshow,allMembers:allMembers,setallMembers:setallMembers, stepsToFollow, addManageStepToFollow, setsteptwomodaldata, lenderemailtexttype: lenderemailtexttype, setlenderemailtexttype: setlenderemailtexttype, elderlawAttorney: elderlawAttorney, 
      setSelectedAgentRolesList: setSelectedAgentRolesList, setmemberRankIdforPrimary: setmemberRankIdforPrimary, memberRankIdforPrimary: memberRankIdforPrimary, illushospitalstep6help: illushospitalstep6help, setillushospitalstep6help: setillushospitalstep6help,
       postsendnotifytoGCMApi: postsendnotifytoGCMApi, setSelectoptionmessage: setSelectoptionmessage, NotifyContinueCallApi: NotifyContinueCallApi, getGCMContactCallNo: getGCMContactCallNo, GCmcontactNo: GCmcontactNo, setwithOutGCMNeedHelpNext: setwithOutGCMNeedHelpNext,
        setstep7illnesshomenext: setstep7illnesshomenext, fullNameofGcm: fullNameofGcm, gcmDetails: gcmDetails, isGCM: isGCM, setIsGCM: setIsGCM, getGCMprofessionalbyUserid: getGCMprofessionalbyUserid, getGCMprofessionalbyUseridforLocate: getGCMprofessionalbyUseridforLocate,
        zsetEOLstep10: setEOLstep10, setEOlStep8Toallcall: setEOlStep8Toallcall, handleShow: handleShow, handleClose: handleClose, setmodalcontantshow: setmodalcontantshow, steptwomodaldata: steptwomodaldata, setGcmDetails: setGcmDetails, setgcmidforcontactno: setgcmidforcontactno, 
        setFullNameOfGCM: setFullNameOfGCM,setSendEmailOrText: setSendEmailOrText, sendEmailOrText: sendEmailOrText, selectedAgentRolesList: selectedAgentRolesList, setNotifyTypeId: setNotifyTypeId, handleShowDocumentModal, setsuccessalert: setsuccessalert, selectoptionId, 
        getGCMprofessionalbyUserid: getGCMprofessionalbyUserid, setAgentdata: setAgentdata, agentData: agentData, agentRoleid: agentRoleid, primaryAgents: primaryAgents, setFullnameofalderlawattorney: setFullnameofalderlawattorney, fullnameofAlderlawattorny: fullnameofAlderlawattorny,
         wayToconnect: wayToconnect, setWaytoconnect: setWaytoconnect, agentRole: agentRole, setAgentrole: setAgentrole, setAllagents: setAllagents,allAgents:allAgents, setPrimaryagents: setPrimaryagents, alljsonData: alljsonData, setAlljsondata: setAlljsondata, setWarningText: setWarningText,
          setwarninngMsg: setwarninngMsg, deathText: deathText,setDeathText: setDeathText, setloader:setloader,hospitalDetails:hospitalDetails, setHospitalDetails:setHospitalDetails,settingDetails:settingDetails, setSettingDetails:setSettingDetails, setnewAlert: setnewAlert,goBack:goBack,
          setGoBack:setGoBack,goNext:goNext, setGoNext:setGoNext,insurenceJosn:insurenceJosn,setInsurenceJosn:setInsurenceJosn,certifyAgent:certifyAgent, setCertifyAgent:setCertifyAgent,setSelectoptionId:setSelectoptionId,emails:emails,setEmails:setEmails,inputValue:inputValue,setInputValue:setInputValue,
          lawFirmMail:lawFirmMail,setLawFirmMail:setLawFirmMail,legalDoc:legalDoc,setlegalDoc:setlegalDoc,stepName:stepName,setStepName:setStepName,setallProfessionals:setallProfessionals,allProfessionals:allProfessionals,flowWithoutProff:flowWithoutProff,setFlowWithoutProff:setFlowWithoutProff}}>

         <Feedback /> 
        <Main />
        {
          showDocument === true && <AccessDocuments showDocument={showDocument} hideView={hideDocument} handleExecutiveModal={handleClose} />
        }

        <Modal centered={true} size="lg" show={show} onHide={handleClose} backdrop="static" >

          {
            (modalcontantshow == "financialRole") ? < FinancelRole /> :
            (modalcontantshow == "elaNotify") ? < ElaNotify /> :
            (modalcontantshow == "illnessMemberWarning") ? <IllnessWrngMember/> :
            (modalcontantshow == "modalInsurence") ? <ModalInsurence/> :
              (modalcontantshow == "step2selectone") ? <Stept2SelectOne setsteptwomodaldata={setsteptwomodaldata} /> :
                (modalcontantshow == "step3selectoption") ? <Step3SelectOption setSelectoptionId={setSelectoptionId} /> :
                  (modalcontantshow == 'illnesswarning') ? <IllnessWarning /> :
                    (modalcontantshow == 'illnessnotifysuccessor') ? <IllnessNotifySuccessor /> :
                      (modalcontantshow == 'step4illness') ? <Step4illness /> :
                        (modalcontantshow == 'illnesshospitalstep6') ? <IllnessHospitalStep6 setillushospitalstep6help={setillushospitalstep6help} /> :
                          (modalcontantshow == 'illnesshospitalstep7') ? <IllnessHospitalStep7 illushospitalstep6help={illushospitalstep6help} /> :
                            (modalcontantshow == 'illnesshomemedical') ? <IllnessHomeMedical /> :
                              (modalcontantshow == 'illnesshomemedicalhelp') ? <IllnessHomeMedicalHelp /> :
                                (modalcontantshow == 'illnesshomegoalstep7') ? <IllnessHomeGoalStep7 /> :
                                  (modalcontantshow == 'eolyesno') ? <EOLYesNo /> :
                                    (modalcontantshow == 'eolwarning') ? <EOLwarning /> :
                                      (modalcontantshow == 'eolnotifysuccessorstep4') ? <EOLNotifySuccessorStep4 /> :
                                        (modalcontantshow == 'eolyesneedno') ? <EOLYesNeedNo /> : (modalcontantshow == 'eolneedyes') ? <EOLNeedYes /> :
                                          (modalcontantshow == 'eolneedyesunanimous') ? <EOLNeedYesUnanimous /> :
                                            (modalcontantshow == 'eolgoalstep7') ? <EOLGoalStep7 /> :
                                              (modalcontantshow == 'eolgcmstep8') ? <EOLGcmStep8 withOutGCMNeedHelpNext={withOutGCMNeedHelpNext} Eolneedhelpsetp7to8={Eolneedhelpsetp7to8} step7illnesshomenext={step7illnesshomenext} /> :
                                                (modalcontantshow == 'contactToelderlawattorney') ? <ContactToelderlawattorney withOutGCMNeedHelpNext={withOutGCMNeedHelpNext} Eolneedhelpsetp7to8={Eolneedhelpsetp7to8} step7illnesshomenext={step7illnesshomenext} /> :
                                                  (modalcontantshow == 'eolaccessdocument') ? <EOLAccessDocument /> :
                                                    (modalcontantshow == 'eolneedhelpstep7') ? <EOLNeedHelpStep7 setEolneedhelpsetp7to8={setEolneedhelpsetp7to8} /> :
                                                      (modalcontantshow == 'eolneedhelpstep9') ? <EOLNeedHelpStep9 EOlStep8Toallcall={EOlStep8Toallcall} /> :
                                                        (modalcontantshow == 'eolgcmcontact') ? <EOLGcmContact /> :
                                                          (modalcontantshow == 'eolstep10') ? <EOLStep10 Eolstep10={Eolstep10} /> :
                                                            (modalcontantshow == 'eolstep9call') ? <EOLstep9Call /> :
                                                              (modalcontantshow == 'deathwarning') ? <DeathWarning /> :
                                                                (modalcontantshow == 'deathnotifysuccessorstep4') ? <DeathNotifySuccessorStep4 /> :
                                                                  (modalcontantshow == 'deathstep5') ? <DeathStep5 setdeathhome={setdeathhome} /> :
                                                                    (modalcontantshow == 'deathhome') ? <DeathHome deathhome={deathhome} /> :
                                                                      (modalcontantshow == 'deathgoalstep7') ? <DeathGoalStep7 /> :
                                                                        (modalcontantshow == 'deathaskagent') ? <DeathAskAgent /> :
                                                                          (modalcontantshow == 'deathcrematedburied') ? <DeathCrematedBuried setcrematedburied={setcrematedburied} /> :
                                                                            (modalcontantshow == 'deathcrematedyesno') ? <DeathCrematedYesNo deathcrematedburied={deathcrematedburied} /> :
                                                                              (modalcontantshow == 'deathcrematedviewguidance') ? <DeathCrematedViewGuidance deathcrematedburied={deathcrematedburied} /> :
                                                                                (modalcontantshow == 'deathburiedno') ? <DeathBuriedNo /> :
                                                                                  (modalcontantshow == 'illnessemailtextpreview') ? <IllnessEmailTextPreview /> :
                                                                                    (modalcontantshow == 'eolemailtextpreview') ? <EOLEmailTextPreview /> : (modalcontantshow == 'deathemailtextpreview') ? <DeathEmailTextPreview /> :
                                                                                      (modalcontantshow == 'financeWarning') ? <FinessWarming forward='financenotifysuccessor' backward="step2selectone" pageName='financeWarning' /> :
                                                                                        (modalcontantshow == "financenotifysuccessor") ? <FinessNotifySuccessor forward='financeOptions' pageName="financenotifysuccessor" /> :
                                                                                          (modalcontantshow == "financeOptions") ? <FinanceOptions setSelectoptionId={setSelectoptionId} setSelectedFinancePage={setSelectedFinancePage} /> :
                                                                                            (modalcontantshow == "paybills") ? <FinanceGoalNStep /> :
                                                                                              (modalcontantshow == "financeGoalNStep2") ? <FinanceGoalNStep2 message="4" showButton={true} goBack="paybills" /> :
                                                                                                (modalcontantshow == "filetaxes") ? <Filetaxes /> :
                                                                                                  (modalcontantshow == "filetaxesGoals") ? <FinanceGoalNStep2 message="5" showButton={true} goBack="filetaxes" /> :
                                                                                                    (modalcontantshow == "manageInverstment") ? <ManageInverstment setRolesName={setRolesName} /> :
                                                                                                      (modalcontantshow == "managerealproperty") ? <ManageReal setRolesName={setRolesName} /> :
                                                                                                        (modalcontantshow == "paybiilsByYourself") ? <PaybiilsByYourself /> :
                                                                                                          (modalcontantshow == "fileTaxesByYourself") ? <FileTaxesByYourself /> :
                                                                                                            (modalcontantshow == "paybillsContactpage") ? <FinanceContactPage goCallPage="paybillCall" roleName="Pay Bills Services" goBack="paybills" forward="paybillsEmailNText" /> :
                                                                                                              (modalcontantshow == "filetaxesContactpage") ? <FinanceContactPage goCallPage="fileTaxCall" roleName="Accountant" goBack="filetaxes" forward="filetaxesEmailNText" /> :
                                                                                                                (modalcontantshow == "manageInvestmentContactpage") ? <FinanceContactPage goCallPage="manageInvestmentCall" roleName={rolesName} goBack="manageInverstment" forward="manageInverstmentEmailNText" /> :
                                                                                                                  (modalcontantshow == "manageRealContactpage") ? <FinanceContactPage goCallPage="manageRealCall" roleName={rolesName} goBack="managerealproperty" forward="manageRealEmailNText" /> :
                                                                                                                    (modalcontantshow == "paybillsEmailNText") ? <FinanceEmailNText goCallPage="" selectedFinacePage={"paybills"} goBack="paybillsContactpage" /> :
                                                                                                                      (modalcontantshow == "filetaxesEmailNText") ? <FinanceEmailNText selectedFinacePage="filetaxes" goBack="filetaxesContactpage" /> :
                                                                                                                        (modalcontantshow == "manageInverstmentEmailNText") ? <FinanceEmailNText selectedFinacePage={"manageInverstment"} goBack="manageInvestmentContactpage" /> :
                                                                                                                          (modalcontantshow == "manageRealEmailNText") ? <FinanceEmailNText selectedFinacePage={"managerealproperty"} goBack="manageRealContactpage" /> :
                                                                                                                            (modalcontantshow == "paybillCall") ? <FinanceCall selectedFinacePage={"paybills"} rmodalcontantshowoleName={rolesName} goBack="paybillsContactpage" /> :
                                                                                                                              (modalcontantshow == "fileTaxCall") ? <FinanceCall selectedFinacePage="filetaxes" roleName={rolesName} goBack="filetaxesContactpage" /> :
                                                                                                                                (modalcontantshow == "manageInvestmentCall") ? <FinanceCall selectedFinacePage={"manageInverstment"} roleName={rolesName} goBack="manageInvestmentContactpage" /> :
                                                                                                                                  (modalcontantshow == "manageRealCall") ? <FinanceCall selectedFinacePage={"managerealproperty"} roleName={rolesName} goBack="manageRealContactpage" /> :
                                                                                                                                    (modalcontantshow == "eolmailforelderattorney") ? <EOLEmailforElderAttorney /> : (modalcontantshow == "eolattornerCall") ? <EOlAttornerCall /> :
                                                                                                                                      (modalcontantshow == "mentalHealthYesOrNo") ? <MentalHealthYesOrNo /> :
                                                                                                                                        (modalcontantshow == "MentalHealthSafe") ? <MentalHealthSafe /> :
                                                                                                                                          (modalcontantshow == "noMentalGcm") ? <MentalHealthGCM /> :
                                                                                                                                            (modalcontantshow == "mentalHealthGCM") ? <FinanceContactPage goCallPage="mentalCall" roleName="Geriatric Care Manager" goBack="MentalHealthSafe" forward="mentalEmailNText" /> :
                                                                                                                                              (modalcontantshow == "mentalEmailNText") ? <FinanceEmailNText goCallPage="mentalCall" selectedFinacePage="MentalHealth" goBack="mentalHealthGCM" /> :
                                                                                                                                                (modalcontantshow == "owndata") ? <FinanceEmailNText goCallPage="mentalCall" selectedFinacePage="MentalHealth" goBack="mentalHealthGCM" /> :
                                                                                                                                                  (modalcontantshow == "mentalCall") ? <FinanceCall selectedFinacePage={selectedFinacePage} roleName="Geriatric Care Manager" goBack="mentalHealthGCM" /> :
                                                                                                                                                    (modalcontantshow == "mentalHealthCallNine") ? <MentailHealthCallNine /> :
                                                                                                                                                      (modalcontantshow == "mentalHealthDiscuss") ? <MentalHealthDiscuss /> :
                                                                                                                                                      (modalcontantshow == "hospitalDetails") ? <HospitalDetails/> :
                                                                                                                                                      (modalcontantshow == "OtherSetting") ? <OtherSetting/> :
                                                                                                                                                        (modalcontantshow == "mentalHealthSuccessorContact") ? <MentalHealthSuccessorContact /> :
                                                                                                                                                          (modalcontantshow == 'mentalHealthWarning') ? <FinessWarming forward='mentalHealthNotifySuccessor' backward="step3selectone" pageName='mentalHealthWarning' /> :
                                                                                                                                                            (modalcontantshow == "mentalHealthNotifySuccessor") ? <FinessNotifySuccessor forward='mentalEmailNTextSuccessor' pageName="mentalHealthNotifySuccessor" /> :
                                                                                                                                                              (modalcontantshow == "mentalEmailNTextSuccessor") ? <FinanceEmailNText goCallPage="mentalCall" selectedFinacePage="MentalHealth" goBack="mentalHealthNotifySuccessor" isSuccessor={true} /> :

                                                                                                                                                                <div>
                                                                                                                                                                  <Modal.Header style={{ backgroundColor: "#871a27" }}>
                                                                                                                                                                    <Row >
                                                                                                                                                                      <Col sm={11}>
                                                                                                                                                                        <h3 className='heading'>
                                                                                                                                                                          Step 2: What role are you needing to trigger?
                                                                                                                                                                        </h3>
                                                                                                                                                                      </Col>
                                                                                                                                                                      <Col sm={1} >
                                                                                                                                                                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                                                                                                                                                                      </Col>
                                                                                                                                                                    </Row>
                                                                                                                                                                  </Modal.Header>
                                                                                                                                                                  <Modal.Body>
                                                                                                                                                                    <h4 className='SelectOptionsh4Tag mb-4 mt-3'>
                                                                                                                                                                      Select option
                                                                                                                                                                    </h4>

                                                                                                                                                                    <Row className='pt-2'>
                                                                                                                                                                      <div className='container justify-content-center  d-flex flex-column flex-lg-row align-items-center' style={{ gap: "0rem" }}>
                                                                                                                                                                        {selectedAgentRolesList.length > 0 && selectedAgentRolesList.sort((a, b) => a.agentRoleId - b.agentRoleId).map((ele) => {
                                                                                                                                                                          return (
                                                                                                                                                                            <>
                                                                                                                                                                              <Col xs={12} sm={6} md={6} lg={6} className={`${(ele.agentRoleId == 1) ? "healthButtonCol align-items-lg-center" : "finacialButtonCol align-items-lg-center"} m-0 p-0 d-flex flex-column align-items-center`}>
                                                                                                                                                                                <h5 className='successorAgent'>{ele?.agentRank + " " + ele?.agentRole}</h5>
                                                                                                                                                                                <button className='healthButtons' data-id={ele?.agentRoleId == 1 ? 1 : 2} onClick={(e) => redirectToModule(e)} > {ele?.agentRoleId == 1 ? "Healthcare Role" : ele?.agentRoleId == 2 ? "Financial Role" : ""}</button>
                                                                                                                                                                              </Col>

                                                                                                                                                                            </>
                                                                                                                                                                          )

                                                                                                                                                                        })}
                                                                                                                                                                      </div>
                                                                                                                                                                    </Row>

                                                                                                                                                                    <div className='backButtonDiv'>
                                                                                                                                                                      <button className='backButton' onClick={handleClose}  >Back</button>
                                                                                                                                                                    </div>
                                                                                                                                                                  </Modal.Body>

                                                                                                                                                                </div>



          }

          <OccuranceCom ref={occurranceRef} />
        </Modal>

      </globalData.Provider>

    </>
  );


  function redirectToModule(e) {
    const agentRoleId = e.target.getAttribute("data-id");
    const selectedAgentRolesListObj = selectedAgentRolesList;
    setAgentroleid(agentRoleId)
    const jsonFindIndex = selectedAgentRolesListObj.findIndex((fil) => (fil.agentRoleId === Number(agentRoleId)));
    let filteredJson = allAgents.length > 0 && allAgents.filter((ele) => { return ele?.agentRoleId == agentRoleId && ele?.agentUserId != stateObj.userId})
    
    setAgentdata(removeDuplicateValue(filteredJson))
    setallMembers(removeDuplicateValue(filteredJson))
    let filteredData = allAgents.length > 0 && allAgents.filter((ele) => { return ele?.agentRoleId == agentRoleId && ele?.agentRankId == 1})
    if (filteredData.length === 0) {
      let filteredDatas = allAgents.length > 0 && allAgents.filter((ele) => { return (ele?.agentRoleId == agentRoleId && (ele?.agentRankId == 2 || ele?.agentRankId == 3 || ele?.agentRankId == 4 || ele?.agentRankId == 5 || ele?.agentRankId == 6)) })
      setPrimaryagents(removeDuplicateValue(filteredDatas))
   
      
   } else {
      setPrimaryagents(removeDuplicateValue(filteredData))
      // setallMembers(allAgents)
    }
    if (jsonFindIndex !== -1) {
      const selectedRankObj = selectedAgentRolesListObj[jsonFindIndex];
      setmemberRankIdforPrimary(selectedRankObj.agentRankId);

      if (agentRoleId === "2") {
        setillushospitalstep6help("finance")
        addManageStepToFollow("pageName", "finance");
        let id = selectedRankObj.agentRankId
        if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
          setmodalcontantshow('financenotifysuccessor')
        } else {
          setmodalcontantshow('financeWarning');
        }
      }
      else {
        setillushospitalstep6help("")
        addManageStepToFollow("pageName", "health");
        setmodalcontantshow('step3selectoption')
      }
    }


  }
}

export default CommonModal