import React,{useState,useEffect,useContext} from 'react';
import { Navigate } from 'react-router-dom';
import commonLib from '../control/commonLib';
import konsole from '../Network/konsole';
import Services from '../Network/Services';
import { AoLoginBaseUrl } from '../Network/url';

export default function RequireAuth({ children }) {
    const AuthToken = commonLib.getSessionStoarge("AuthToken");
    const authed = (AuthToken !== '' && AuthToken !== null)? true : false;

    // const { setLoader } = useContext(UserContext)
    const [ validUser, setValidUser ] = useState(false);
    const [roleId,setRoleId]=useState(null)


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const stateObj = commonLib.getObjFromStorage("stateObj");
            setRoleId(stateObj.roleId)
            const promises = Services.getLoggedInUser(stateObj?.userId, stateObj?.appState, stateObj?.loggenInId, stateObj?.roleId)
            // setLoader(true);
            promises.then(response => {
                if (response) {
                    //consolee.log("responsresponse",response.data.data)
                    // setLoader(false);
                    setValidUser(true);
                }
            }).catch((error)=> {
                //consolee.log("responsresponse",error)
                // setLoader(false);
                if(stateObj.roleId==5){
                    commonLib.unAuthurizeAccess();
                }else{
                    commonLib.closeParentModal()
                }
            })
            .finally(()=>{
                // setLoader(false);
            })
        }
        return () => {
            isMounted = false;
        }
    }, [])
    // konsole.log("authed", authed, AuthToken) ;
    return authed === true ? 
        <>
            {children}
        </>
     : (roleId==5 || roleId ==null) ?  window.location.replace(`${AoLoginBaseUrl}Account/Signin`):commonLib.closeParentModal()
}