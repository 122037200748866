import React, { useContext, useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Buffer } from 'buffer';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import '../Modal.css';
import moment from 'moment';
import { returnFname,isNotValidNullUndefinedfile,replaceDocumentFile,removeDuplicateValue} from '../../control/Constant'
import DatePicker from 'react-datepicker';
import commonLib from '../../control/commonLib';
import 'react-datepicker/dist/react-datepicker.css';

const IllnessWrngMember = () => {
    const { handleClose, setmodalcontantshow,setnewAlert,agentRoleid,setlegalDoc,steptwomodaldata, primaryAgents, setCertifyAgent, allAgents, allMembers,setLawFirmMail,setWarningText,setwarninngMsg} = useContext(globalData)
    let agents = isNotValidNullUndefinedfile(allMembers) == true && allMembers.length > 0 && allMembers?.filter(ele => ele?.agentRankId != 1)
    let primaryAgentnames = allMembers?.length == 0 ? "" : agents.map((ele, index) => { return (ele.length == 1 ? `${ele.fullName}(${ele?.agentRank} agent),` :   `${" " + `${ele.fullName} (${ele?.agentRank} agent)`}${(agents.length - 1 === index) ? '' : ','}`) })
    let primaryAgentname = primaryAgents.length == 0 ? "primary Agent" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})

    const stateObj = commonLib.getObjFromStorage("stateObj");
    let loginUserRank = allAgents.filter(ele => ele?.agentRoleId == agentRoleid && ele?.agentUserId == stateObj.userId)

    const [yesChecked, setYesChecked] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
    const [noChecked, setNoChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const [isYes, setIsYes] = useState("")
    const [showDocumentView, setshowDocumentView] = useState(false)
    const [regex, setregex] = useState(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?" "]*$/)

      
    const replaceContent = async (e) => {
        e.preventDefault();
        if((!regex.test(formData.q1) || !isNotValidNullUndefinedfile(formData.q1)) || (!regex.test(formData.q4) || !isNotValidNullUndefinedfile(formData.q4)) || (!regex.test(formData.q2) || !isNotValidNullUndefinedfile(formData.q2) ) || (!regex.test(formData.q3) || !isNotValidNullUndefinedfile(formData.q3) && primaryAgentnames.length != 0) ||  (!isNotValidNullUndefinedfile(selectedDate))){
          
            setnewAlert({type: "warning", text: "Please fill your details"});
        setTimeout(() => {
            setnewAlert({type: "", text: ""})
          }, 3000)
          }else{
       
        const fetchedDocx = await fetch('AgentCertification.docx');
        const zip = new JSZip();
      
        const buffer = await fetchedDocx.arrayBuffer();
        const loadedZip = await zip.loadAsync(buffer);
        
           const currentDate = new Date();
          
      
        const replacements = [
            { textToReplace: "LOGINAGENT", replacementText: userLoggedInDetail.loginUserName},
            { textToReplace: "CLIENTNAME", replacementText: steptwomodaldata?.memberFullName},
            { textToReplace: "CFRISTNAME", replacementText: returnFname(steptwomodaldata?.memberFullName)},
            { textToReplace: "COUNTY", replacementText: formData?.q2},
            { textToReplace: "CITY", replacementText: formData?.q4},
            { textToReplace: "RANK", replacementText: loginUserRank.length > 0 && removeDuplicateValue(loginUserRank)[0]?.agentRank},
            { textToReplace: "CURRENTDAY", replacementText: formatDate(currentDate)},
            { textToReplace: "DATES", replacementText: isNotValidNullUndefinedfile(selectedDate) && formatDate(selectedDate)},
            { textToReplace: "SECOND", replacementText: !isNotValidNullUndefinedfile(formData?.q3) ? "" :`, (2) ${formData?.q3}`},
            { textToReplace: "FIRST", replacementText: `(1) ${formData?.q1}`},
        ];
      
        for (const [relativePath, file] of Object.entries(loadedZip.files)) {
          if (relativePath.endsWith('.xml')) {
            let content = await file.async('text');
            replacements.forEach(({ textToReplace, replacementText }) => {
              content = content.replace(new RegExp(textToReplace, 'g'), replacementText);
            });
            loadedZip.file(relativePath, content);
          }
        }
      
        const updatedContent = await loadedZip.generateAsync({ type: 'uint8array' });
        const blob = new Blob([Buffer.from(updatedContent)], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        saveAs(blob, 'Agent Certification-Details');
        
    }
      };
      function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
      
        const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
        const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      
        return `${day}${getDaySuffix(day)} ${month.toUpperCase()} ${year}`;
      }
      
      function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }

    const [formData, setFormData] = useState({
        q1: '',
        q2: '',
        q3: '',
        q4: ''

    });
    const handelChange = (e) => {
        if (e.target.name == "Yes") {
            setNoChecked(false)
            setYesChecked(true)
            handelSubmit(e)
        } else {
            setNoChecked(true)
            setYesChecked(false)
            sendNext("no")
          
        }

    }
    const handelSubmit = async(e) => {
        e.preventDefault();
        const newErrors = {};
        // const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/

        if (!regex.test(formData.q1) || !isNotValidNullUndefinedfile(formData.q1)) {
            newErrors.q1 = 'Please enter your answer';
        }
        if (!regex.test(formData.q2) || !isNotValidNullUndefinedfile(formData.q2)) {
            newErrors.q2 = 'Please enter your city';
        }
        if (!regex.test(formData.q3) || !isNotValidNullUndefinedfile(formData.q3) && primaryAgentnames.length != 0) {
            newErrors.q3 = 'Please enter your answer';
        }
        if (!regex.test(formData.q4) || !isNotValidNullUndefinedfile(formData.q4)) {
            newErrors.q4 = 'Please enter your county';
        }
        if (!isNotValidNullUndefinedfile(selectedDate) || selectedDate == false || !isNotValidNullUndefinedfile(selectedDate)) {
            newErrors.selectedDate = 'Please enter DPoA date';
        }
        if (Object.keys(newErrors).length > 0) {
            setYesChecked(false)
            setErrors(newErrors);
            return;
        }
        let newText = `(1) ${formData.q1} ${!isNotValidNullUndefinedfile(formData.q3) ? "" : ", (2)" + formData?.q3}`
        const currentDate = new Date();
        const replacements = [
            { textToReplace: "LOGINAGENT", replacementText: userLoggedInDetail.loginUserName},
            { textToReplace: "CLIENTNAME", replacementText: steptwomodaldata?.memberFullName},
            { textToReplace: "CFRISTNAME", replacementText: returnFname(steptwomodaldata?.memberFullName)},
            { textToReplace: "COUNTY", replacementText: formData?.q2},
            { textToReplace: "CITY", replacementText: formData?.q4},
            { textToReplace: "RANK", replacementText: loginUserRank.length > 0 && removeDuplicateValue(loginUserRank)[0]?.agentRank},
            { textToReplace: "CURRENTDAY", replacementText: formatDate(currentDate)},
            { textToReplace: "DATES", replacementText: isNotValidNullUndefinedfile(selectedDate) && formatDate(selectedDate)},
            { textToReplace: "SECOND", replacementText: !isNotValidNullUndefinedfile(formData.q3) ? "" :`, (2) ${formData?.q3}`},
            { textToReplace: "FIRST", replacementText: `(1) ${formData?.q1}`},
        ];
        let result = await replaceDocumentFile(replacements)
        
        setlegalDoc(result)
        // setshowDocumentView(true)
        setLawFirmMail(newText)
        sendNext("yes")
        
    }
    const handelClick = (e) => {
      const { name, value} = e.target;
      setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    }
    const sendBack = () => {
        setmodalcontantshow("illnesswarning")
        setCertifyAgent("")
    }
    const handleClosed =()=>{
        setshowDocumentView(false)
        setYesChecked(false)
        
    }
    const sendNext =(data)=>{
     if(data == "yes"){
        setIsYes(true)
     }else{
        setIsYes(false)
     }
    }

const proceedNext=()=>{
    if(isYes === true){
        setshowDocumentView(false)
        setCertifyAgent("illnessYes")
        setmodalcontantshow('elaNotify')
    }else if(isYes === false){
        setmodalcontantshow('step4illness')
        setCertifyAgent("illnessNo")
      
    }else if(isYes == "" && stateObj.roleId !== "5"){
        setmodalcontantshow('step4illness')
        setCertifyAgent("illnessNo")
    }else{
        setwarninngMsg(true)
        setWarningText(`Please choose "Yes" or "No" before clicking on "Proceed"`)
        setTimeout(() => {
            setwarninngMsg(false)
          }, 3000)
    }
}

    const setDate =(date)=>{
        setSelectedDate(date)
        setErrors({
            ...errors,
            ["selectedDate"]: ''
        })

    }
       return (
           <>

               <Modal.Header style={{ backgroundColor: "#871a27" }}>
                   <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                       <h3 className='heading text-center' >
                       Information needed to create certification
                       </h3>
                   </Modal.Title>

                   <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

               </Modal.Header>
               <Modal.Body>
                   <div style={{ padding: "2rem" }}>

                       <form className="plainText">
                           <div className="form-group">
                              <label htmlFor="county"><li>Please explain why {primaryAgentname} (Primary Agent), cannot act as agent ? *</li></label>
                               <textarea className="message-inputs mt-1 mb-1" name="q1" id="q1" placeholder="Enter your answer" defaultValue={""} onChange={handelClick} />
                               {errors.q1 && <p className='text-danger m-0'> <div className="error">{errors.q1}
                               </div></p>}
                           </div>
                           {primaryAgentnames.length == 0 ? "" : 
                           <div className="form-group">
                           <label htmlFor="county"><li>Please explain why {primaryAgentnames} cannot act as agent ? *</li></label>
                            <textarea className="message-inputs mt-1 mb-1" name="q3" id="q3" placeholder="Enter your answer" defaultValue={""} onChange={handelClick} />
                            {errors.q3 && <p className='text-danger m-0'> <div className="error">{errors.q3}
                            </div></p>}
                        </div>
                             }
                           
                           <div className="form-group">
                               <label htmlFor="county"><li>What city will you sign this certification in ?*</li></label>
                               <input className="message-inputs mt-1 mb-1" type='text' name="q2" id="q2" placeholder="Enter your city" defaultValue={""} onChange={handelClick} />
                               {errors.q2 && <p className='text-danger m-0'> <div className="error">{errors.q2}
                               </div></p>}
                           </div>
                           <div className="form-group">
                               <label htmlFor="county"><li>What county will you sign this certification in ?*</li></label>
                               <input className="message-inputs mt-1 mb-1" type='text' name="q4" id="q4" placeholder="Enter your county" defaultValue={""} onChange={handelClick} />
                               {errors.q4 && <p className='text-danger m-0'> <div className="error">{errors.q4}
                               </div></p>}
                           </div>
                           <div className="form-group">
                               <label htmlFor="county"><li>What date was the Power of Attorney signed ? *</li></label>
                               <DatePicker name="date" className="form-group"placeholderText="MM/DD/YYYY" selected={selectedDate} onChange={(date)=>setDate(date)} />
                               {/* <img title='Calander'src="/icons/Calender.svg"className='smallIconSizes ms-1'alt="Calander" /> */}
                               {errors.selectedDate && <p className='text-danger m-0'> <div className="error">{errors.selectedDate}
                               </div></p>}

                           </div>
                           <div>
                           </div>
                            <div className='d-flex mb-4'>
                            <h6 className='mt-2 me-2' >Download the mandatory certification required</h6><button  className="btn-btn"style={{background:"rgb(135, 26, 39)",color:"white",borderRadius:"25px",padding:"6px",border:'none'}} onClick={replaceContent}>Download</button>

                            </div>
                            <p style={{ borderBottom: "1px solid #80808091", paddingBottom: "1rem",color:"rgb(135, 26, 39)",fontWeight:"600"}}>Note : Please Upload Durable Power of Attorney Document and Agent certification Document in the next screen after clicking the <span className='fw-bolder'style={{color:"black"}}>Yes</span> option </p>
                           <div className="form-group">
                               <label htmlFor="county"><li>Would you like the legal counsel to be notified (we suggest you do) ?</li></label>
                               <div className='d-flex  ms-4' style={{ width: "fit-content" }} >
                                   <input className='me-2' name="Yes" checked={yesChecked} type='radio' onChange={(e) => handelChange(e)} /><span className='me-4'>Yes</span>
                                   <input className='me-2' name="No" checked={noChecked} type='radio' onChange={(e) => handelChange(e)} /><span className='me-1'>No</span>
                               </div>
                           </div>


                       </form>
                   </div>


                   <div className='buttonDiv'>
                   </div>
                   <div className='warnigbackbuttonDiv d-flex justify-content-between'>
                       <button className='backButton' onClick={() => sendBack()}>Back</button>
                       <button className='proceedBtn' style={{ marginRight: "21px", width: "8rem", important: "true" }} onClick={() => proceedNext()}>Proceed</button>
                   </div>
               </Modal.Body>
               <Modal centered={true} size="lg" show={showDocumentView} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Document View
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClosed}>x</button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>      
                <h3 className='SelectOptionsh4Tag  mb-0 mt-3'>For you to act as primary, you will need to sign a certification, under penalty of perjury, that all agents ahead of you cannot act and that you are taking over this duty in good faith. It would be appropriate for you to contact Life Point Law to get counsel before you take over. Would you like to contact the law firm ?</h3>
                <div className="d-flex justify-content-center">
           
               <button className="healthButton"onClick={()=>sendNext("yes")}>Yes</button>
               <button className="healthButton"onClick={()=>sendNext("no")}>No</button>
             

           
      
         </div>               
                </Modal.Body>
            </Modal>
           </>
    );
}

export default IllnessWrngMember