import config from "../config.json"

const demo = config.DEMO;
// export const BASE_URL = (demo) ? "https://aointakeuatapi.azurewebsites.net" : "https://aointakeapi.azurewebsites.net";
// export const AoLoginBaseUrl = (demo) ? "https://aologinuat.azurewebsites.net/" : "https://aologin.azurewebsites.net/";
// export const aorgLink = (demo)? "https://aoorguat.azurewebsites.net/" : "https://aoorgdev.azurewebsites.net/";
export const BASE_URL = (demo) ? "https://aointakeuatapi.agingoptions.com" : "https://aointakeapi.azurewebsites.net";
export const AoLoginBaseUrl = (demo) ? "https://aologinuat.agingoptions.com/" : "https://Unilogin.agingoptions.com/";
export const aorgLink = (demo)? "https://aoorguat.agingoptions.com/" : "https://ResourceGuide.agingoptions.com/";

let AoUrl ={
  postgetUserByAgentId:'/api/UserAgent/getUserByAgentId',
  getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',

  getFileCabinetType:'api/FileCabinet/GetFileCabinetList',
  postFileDocumentCabinetById:'/api/FileCabinet/GetUserDocsByFileCabinet',
  postDownloadUserDocument:'/api/FileUpload/downloadUserDocuments/',
  getFileCabinetRemarksPath:'/api/FileCabinet/getFileRemarks',
  postFileCabinetRemarksPath:'/api/FileCabinet/addFileRemarks',
  putFileCabinetRemarksPath:'/api/FileCabinet/updateFileRemarks',
  

  postsendNotifyemail:'/api/SendNotifyEmail/notifyEmailToMemberRelative',
  postsendNotifyemaillist:'/api/SendNotifyEmail/notifyMemberRelativeList', ////////////
  getprofessionalusergetmembergcm:'/api/ProfessionalUser/Get-Member-GCM-By-UserId/',
  getcontactbyuserid:'/api/Contact/get-contact-by-userid/',
  postsendNotifyemailGCM:'/api/SendNotifyEmail/notifyEmailGCM',
  filecabinetPreparebyUrl:'api/FileCabinet/PreparedBy/',
  filecabinetfilestatus:'/api/FileCabinet/FileStatus',
  filecabinetdocumenturl:'/api/UserFileCabinet/Documents/',
  postfileuploaddownloadurl:'/api/FileUpload/downloadUserDocuments/',
  getUserAgentByAgentId:'/api/UserAgent/getUserByAgentId',
  upsertUserAgent:'/api/UserAgent/upsertUserAgent',
  getSubtenantDetailPath: 'api/Subtenant/get-subtenant-details',
  getUserListByRoleId: '/api/User/getUserListByRoleId/',
  getUserListByRoleId:'/api/User/getUserListByRoleId',
  getMember: "/api/Member",
  getUserDetailByEmailId: "/api/Account/GetUserDetailByEmailId",
  getPostCommMediumPath:'/api/CommMedium/GetCommMedium',
  getTextTemplate: "/api/TextTemplates/GetTextTemplates",
  GetEmailTemplate: "/api/EmailTemplates/GetEmailTemplates",
  postSendTextPath:'/api/TextComm/SendText',
  PostEmailCom: "/api/EmailComm/SendEmailComm",
  getsubdetails: '/api/Subtenant/get-subtenant-details',
  validateUserLinks:'/api/UserLinks/ValidateUserLink',
  agentActivationUserByUserId:'api/User/AddMemberUserByUserIdAsync',
  getProfessionalByUserIdPath: 'api/ProfessionalUser/Get-ProfessionalUser-By-UserId/',
  getUserDetailsByEmailId: '/api/Account/GetUserDetailByEmailId',
  getNotifyMessage: '/api/NotifyMessage/getNotifyMessage/',
  getUseragents:"/api/UserAgent/getUserAgent",
  getSubjectResponse:"/api/Subject/get-user-subject/",
  getsubjectForFormLabelId:"/api/Subject/get-subject-for-form-label-id",
  getProfessionalurl:'/api/2/ProfessionalUser/getMemberProfessionals',
  getSharedFileStatus:'/api/2/UserFileCabinet/GetSharedFileStatus',
  getUserFileCabinetFile:'/api/2/UserFileCabinet/Documents/',
  fileUploadPath:'/api/FileUpload/uploadUserDocuments',
  postFeedback:'/api/Feedback/add-feedback',
  sendMailFeedBack:'/api/EmailComm/SendEmailToMultipleNAttachmentAsync',
  sendPostEmailCC: '/api/EmailComm/SendEmailWithCCAsync',
  getFeedbackPriority:'/api/FeedbackPriority/GetFeedbackPriority',
  // getClintHealthInfo:'api/UserInsurance/get-UserInsurance-by-userid',
  getClintHealthInfo:'/api/UserLongTermIns/',
  postEmail:'api/EmailComm/SendEmailToMultipleNAttachmentAsync',
  postEmailMultiFile:'/api/EmailComm/SendEmailCommNMultiAttachmentAsync',
  getBase64File : "/api/FileUpload/documents/",
  getProfessionaluser :"api/2/ProfessionalUser/getProfessionalUsers",
  postProfessionaluser : "api/2/ProfessionalUser/upsert-ProfessionalUser",
  getAddressByUserId : "api/Address/get-address-by-userid/",
  postLogUserout: BASE_URL + '/api/User/LogoutUser/'
}

export default AoUrl;