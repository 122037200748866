import React, { useState,useContext,useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import { aorgLink, aorgLinkFunc,returnProfessionals,returnPostProfessional,returnGender} from "../../control/Constant";
import { returnFname } from '../../control/Constant'
import commonLib from "../../control/commonLib";
import "./Warning.css";
import ShowPreofessionlDetails from "../../ShowPreofessionlDetails";

const IllnessHospitalStep7 = (props) => {
  const { setillushospitalstep6help, handleClose,setFlowWithoutProff,setmodalcontantshow, steptwomodaldata, isGCM, fullNameofGcm, setstep7illnesshomenext, modalcontantshow, addManageStepToFollow,gcmDetails, stepsToFollow ,getGCMprofessionalbyUserid,setloader} = useContext(globalData)
  const [gcmData, setGcmData] = useState()
  const [showGcmModal, setShowGcmModal] = useState(false)
  const clicknextcontactbutton = () => {
    setstep7illnesshomenext('IllnessHospital')
    setmodalcontantshow('eolgcmstep8')
  }

  
  const clickbackbutton = () => {
    setillushospitalstep6help('');
    setmodalcontantshow('illnesshospitalstep6');
    addManageStepToFollow("setillushospitalstep6help", "");

  }

  
  useEffect(() => {
    // ProfessionalUsers(4,13,1,30)// ela
    if(isGCM == 'false'){
      ProfessionalUsers(1,7,1,500)// gcm
    }
   
  }, [])



  const ProfessionalUsers = async (ProSerDescId,ProTypeId,PageNumber,RowsOfPage) => {
    let userId = steptwomodaldata?.memberUserId
    const result = await returnProfessionals(ProSerDescId,ProTypeId,PageNumber,RowsOfPage,setloader,userId);
    const shuffledUsers = result.length > 0 && result.sort(() => Math.random() - 0.5);
    const usersToShow = shuffledUsers.slice(0, 6);
    
    setGcmData(usersToShow)
    if (result == 'err')return;
};
const Confirmations = async(text,setshowConfrimation)=>{
    setFlowWithoutProff(text)
    setmodalcontantshow('eolgcmstep8')
    setstep7illnesshomenext('IllnessHospital')
  // let data = userData
  //   const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  //   const result = await returnPostProfessional(0, data?.proUserId,data?.proCatId,steptwomodaldata?.memberUserId,true,true,userLoggedInDetail?.loggedUserId,setloader)
  //   getGCMprofessionalbyUserid(7,true)
  //   setshowConfrimation(false)
  //   setShowGcmModal(false)
  //   if (result == 'err')return;

 }
  
  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11}>
            <h3 className="heading">Step 7: Goal and Steps </h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>

        {
          
          (isGCM == 'true') ?
          <>
            <h3 className="SelectOptionsh4Tag mb-4 mt-2">
            {returnFname(steptwomodaldata?.memberFullName)}'s hope is to access any post hospital care at home. 
             To that end, {returnGender(steptwomodaldata?.memberGenderId,1)} selected you to work with {fullNameofGcm} in the role of a Geriatric Care Manager
            who will work with the hospital to organize {returnFname(steptwomodaldata?.memberFullName)}'s care at home if at all possible.<br/><br/>
             If coming home is not possible then {fullNameofGcm} will help you select the facility best able to help {returnFname(steptwomodaldata?.memberFullName)}.
           Your job is to work to ensure that no one is taking advantage of {returnFname(steptwomodaldata?.memberFullName)} or leaving {returnGender(steptwomodaldata?.memberGenderId,3)} neglected.<br/><br/>
           Please contact {fullNameofGcm} (GCM)
              
            </h3>

            {/* (props.illushospitalstep6help == 'Discharge') ?
              <h3 className="SelectOptionsh4Tag mb-4 mt-2">
                {returnFname(steptwomodaldata.memberFullName)} selected you to work with {fullNameofGcm} in the role of a Geriatric Care Manager who will work with the hospital to organize {returnFname(steptwomodaldata.memberFullName)}'s care at home if at all possible.<br/><br/>
                If coming home is not possible then {returnFname(fullNameofGcm)} will help you select the facility best able to help {returnFname(steptwomodaldata.memberFullName)}.
                Your job is to work to ensure that no one is taking advantage of {returnFname(steptwomodaldata.memberFullName)} or leaving {returnFname(steptwomodaldata.memberFullName)} neglected.<br/><br/>
                Please contact {returnFname(fullNameofGcm)}-Geriatric Care Manager (GCM).
              </h3>
             : (props.illushospitalstep6help == 'Care') ?
                <h3 className="SelectOptionsh4Tag mb-4 mt-2">
                  {returnFname(steptwomodaldata.memberFullName)} selected you to work with a {fullNameofGcm} in the role of a Geriatric Care Manager, who will work with the hospital to organize {returnFname(steptwomodaldata.memberFullName)}, coming to the home.<br/><br/>
                  If coming home is not possible, then a Geriatric Professional will help you select the facility best able to help {returnFname(steptwomodaldata.memberFullName)}.
                  Your job is to work to ensure that no one is taking advantage of {returnFname(steptwomodaldata.memberFullName)} or leaving {returnFname(steptwomodaldata.memberFullName)} neglected.<br/><br/>
                  Please contact {returnFname(fullNameofGcm)}-Geriatric Care Manager (GCM).
                </h3>
                : '' */}
        
              <div className="d-flex justify-content-center mb-3">
                <button className="healthButton" onClick={clicknextcontactbutton}> Contact  {fullNameofGcm}</button>
                {/* <button className="healthButton" onClick={()=>setShowGcmModal(true)}>GCM Details</button> */}
              </div>
              
            
            </> : (isGCM == 'false') ?
              <>
                {(props.illushospitalstep6help == 'Discharge') ? 
                  <>
                 <h4 className="SelectOptionsh4Tag">{returnFname(steptwomodaldata?.memberFullName)} would like you to arrange for any post hospital care to come to the home,
                      if at all possible. Likely you will be pressured or strongly encouraged to have {returnFname(steptwomodaldata?.memberFullName)} discharged to a rehabilitation center or some care facility, which will put you at odds with {steptwomodaldata?.memberGenderId == 1 ? "his":"her"} wishes.<br/><br/>
                      So that you can explore all options, you should consider working with a Geriatric Care Manager (GCM) (specialists who can help you assess whether or not it is appropriate or feasible for {returnFname(steptwomodaldata?.memberFullName)} to receive
                      care at home as {returnGender(steptwomodaldata?.memberGenderId,1)} desires). You will find names of GCMs below. You are encouraged to reach out to them before you make a final decision on where to have {returnFname(steptwomodaldata?.memberFullName)} access care.
                      The GCM will work with the hospital to organize {returnFname(steptwomodaldata?.memberFullName)}'s care at home if that is at all possible.<br/><br/>
                      If coming home is not possible then the GCM will help you select the facility best able to help {returnFname(steptwomodaldata?.memberFullName)}, so {returnGender(steptwomodaldata?.memberGenderId,1)} does not
                      end up enduring multiple moves because you did not find the right fit the first time. You will make the final decision based on input from the GCM and other sources. Do consider contacting the GCM.
                    </h4>
                   </>
                :
                  (props.illushospitalstep6help == 'Care') ?
                  <h4 className="SelectOptionsh4Tag">{returnFname(steptwomodaldata?.memberFullName)} would like you to arrange for any post hospital care to come to the home,
                  if at all possible. Likely you will be pressured or strongly encouraged to have {returnFname(steptwomodaldata?.memberFullName)} discharged to a rehabilitation center or some care facility, which will put you at odds with {returnGender(steptwomodaldata?.memberGenderId,2)} wishes.<br/><br/>
                  So that you can explore all options, you should consider working with a Geriatric Care Manager (GCM) (specialists who can help you assess whether or not it is appropriate or feasible for {returnFname(steptwomodaldata.memberFullName)} to receive
                  care at home as {returnGender(steptwomodaldata?.memberGenderId,1)} desires). You will find names of GCMs below. You are encouraged to reach out to them before you make a final decision on where to have {returnFname(steptwomodaldata.memberFullName)} access care.
                  The GCM will work with the hospital to organize {returnFname(steptwomodaldata?.memberFullName)}'s care at home if that is at all possible.<br/><br/>
                  If coming home is not possible then the GCM will help you select the facility best able to help {returnFname(steptwomodaldata?.memberFullName)}, so {returnGender(steptwomodaldata?.memberGenderId,1)} does not
                  end up enduring multiple moves because you did not find the right fit the first time. You will make the final decision based on input from the GCM and other sources. Do consider contacting the GCM.
                </h4>
                    : ''
                    }
                <div className="d-flex justify-content-center">
                <button className="healthButton" onClick={()=>setShowGcmModal(true)}> Find GCM</button>
                </div>
              </> : ''
        }

        <div className='stepsevenbuttonsDiv'>
          <button className="backButton" onClick={clickbackbutton}> Back
          </button>
          {
            (isGCM == 'true') ? <>
              {(props.illushospitalstep6help == 'Care') ? <>
                {/* <button className='backButton' style={{backgroundColor:"#d79b48"}} onClick={clicknextcontactbutton}>
            Next</button> */}
              </>
                : ''}
            </>
              : ''
          }

        </div>
      </Modal.Body>
      {showGcmModal == true && 
       <ShowPreofessionlDetails showGcmModal={showGcmModal} setShowGcmModal={setShowGcmModal} professData={gcmData} Confirmations={Confirmations} professType="Geriatric Care Manager"isProfess={isGCM}singleUserData={gcmDetails} />}
      
    </>
  )
}
export default IllnessHospitalStep7;